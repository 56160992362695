
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { CuratedContentQuery, CuratedContentSlug } from 'generated/graphql';
import CurateContentContainerList from 'src/components/curated-content-container-list';
import HeadTags from 'src/components/head-tags';
import Page from 'src/components/page';
import StageSlider from 'src/components/stage-slider/stage-slider';
import { useCuratedContent } from 'src/hooks/use-curated-content';
import useErrorHandler from 'src/hooks/use-error-handler';
import useTranslate from 'src/hooks/use-translate';
import { EmptyPageParameters } from 'src/types';
import { incrementalServerSideWithQuery } from 'src/utilities/ssr-helpers';
type DiscoverPageProps = {
    fallbackData: CuratedContentQuery;
};
/**
 * The discover page displays a stage slider and a list of curated sliders and banners.
 * It is the home page for authenticated users.
 */
export default function DiscoverPage({ fallbackData }: DiscoverPageProps) {
    const t = useTranslate();
    const handleError = useErrorHandler();
    const { data: curatedContentData } = useCuratedContent(CuratedContentSlug.Discover, fallbackData);
    // If there is an error and no data, we can't render anything
    if (!curatedContentData?.curatedContent) {
        handleError(new Error('Could not load data for this page'));
        return <></>;
    }
    return (<Page testId="discover-page">
      <HeadTags title={t('page__discover_title')}/>
      {curatedContentData.curatedContent.stageSlider && (<StageSlider items={curatedContentData.curatedContent.stageSlider}/>)}

      {curatedContentData.curatedContent.curatedContent && (<CurateContentContainerList items={curatedContentData.curatedContent.curatedContent}/>)}
    </Page>);
}
const getStaticProps = incrementalServerSideWithQuery<EmptyPageParameters, DiscoverPageProps>(async ({ sdk }) => {
    const fallbackData = await sdk.curatedContent({ slug: CuratedContentSlug.Discover });
    return {
        props: { fallbackData },
        revalidate: 3600
    };
});

    async function __Next_Translate__getStaticProps__1916f7c44f5__(ctx) {
      const res = await getStaticProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/discover',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__1916f7c44f5__ as getStaticProps }
  